module.exports = [{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-google-analytics/gatsby-browser'),
      options: {"plugins":[]},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-offline/gatsby-browser'),
      options: {"plugins":[]},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-segment-js/gatsby-browser'),
      options: {"plugins":[],"prodKey":"YcmrIcBG8ruYW5mXfJrR3LpPjqoAv0H9","devKey":"SEGMENT_DEV_WRITE_KEY","trackPage":true,"delayLoad":false,"delayLoadTime":1000},
    }]
